.react-select-container {
  .react-select__control {
    font-family: $form-select-font-family;
    @include font-size($form-select-font-size);
    font-weight: $form-select-font-weight;
    line-height: $form-select-line-height;
    color: $form-select-color;
    background-color: $form-select-bg;
    border: $form-select-border-width solid $form-select-border-color;
    @include border-radius($form-select-border-radius, 0);
    @include box-shadow($form-select-box-shadow);
    @include transition($form-select-transition);
    appearance: none;

    &--is-disabled {
      color: $form-select-disabled-color;
      background-color: $form-select-disabled-bg;
      border-color: $form-select-disabled-border-color;
    }

    &--is-focused {
      border-color: $form-select-focus-border-color;
      outline: 0;
      @if $enable-shadows {
        @include box-shadow($form-select-box-shadow, $form-select-focus-box-shadow);
      } @else {
        // Avoid using mixin so we can pass custom focus shadow properly
        box-shadow: $form-select-focus-box-shadow;
      }
    }
  }

  .react-select__value-container {
    padding: $form-select-padding-y $form-select-padding-x $form-select-padding-y $form-select-padding-x;
  }

  .react-select__indicator {
    width: $form-select-indicator-padding;
  }

  .react-select__single-value {

    .badge {
      display: none
    }
  }

  .react-select__option {
    margin-top: 0.5rem;
    border-top: 1px solid $dropdown-divider-bg;

    &:first-of-type {
      border-top-color: transparent;
    }

    .badge {
      background-color: none;
      color: $dark;
      border: 1px solid $gray-400;
    }

    &--is-focused:not(.react-select__option--is-selected) {
      border-radius: 10px;
      color: $dropdown-link-hover-color;
      text-decoration: if($link-hover-decoration == underline, none, null);
      background-color: #f0f0f0;
      border-top-color: transparent;

      .badge {
        background-color: $light;
        color: $dark;
      }
    }

    &--is-focused + .react-select__option {
      border-top-color: transparent;
    }

    &--is-selected {
      border-radius: 10px;
      border-top-color: transparent;

      .badge {
        background-color: $light;
        color: $dark;
      }
    }

    &--is-selected + .react-select__option {
      border-top-color: transparent;
    }
  }

  .react-select__menu-list {
    padding: 20px;
  }

  .dropdown-indicator {
    background-image: escape-svg($form-select-indicator);
    background-repeat: no-repeat;
    background-position: right 0 center;
    background-size: $form-select-bg-size;
    width: 16px;
    height: 12px;
  }
}
