  .icon-svg {
        height: 100px;
        width: 100px;
        margin-top:65px !important;
    }

  @media only screen and (min-width :320px) {
      .icon-svg {
          height: 40px;
          width: 40px;
          margin-top:35px !important;
      }

      .heroTitle {
          margin-left: 0 !important;
          margin-right: 0 !important;
          font-size: 19px;
          font-weight: 600;
          padding: 0;
          margin-top: 20px;
          padding-bottom: 5px;
          color:#FFFFFF;
      }
      .heroSubTitle {
          margin-left: 0 !important;
          margin-right: 0 !important;
          text-align: center !important;
          font-size: 15px;
          line-height: 25px;
          font-weight: 400;
          padding: 0;
          margin: 0;
          padding-bottom: 20px;
          color:#FFFFFF;
          opacity:0.6;
      }

      .heroHeader {
          font-size: 26px;
          line-height: 30px;
          font-weight: 400;
          padding-left: 10px;
          padding-right: 10px;
          padding-bottom: 5px
      }
      .heroText {
          font-size: 20px;
          line-height: 24px;
          font-weight: 300;
          padding-left: 0;
          padding-right: 0
      }
  }

  @media only screen and (min-width :375px) {
      .icon-svg {
            height: 60px;
            width: 60px;
            margin: 3px;
            margin-top:35px !important;
        }

      .heroTitle {
          margin-left: 0 !important;
          margin-right: 0 !important;
          font-size: 24px;
          font-weight: 600;
          padding: 0;
          margin-top: 20px;
          padding-bottom: 5px;
          color:#FFFFFF;
      }
      .heroSubTitle {
          margin-left: 10px !important;
          margin-right: 10px !important;
          text-align: center !important;
          font-size: 18px;
          line-height: 28px;
          font-weight: 400;
          padding: 0;
          margin: 0;
          padding-bottom: 20px;
          color:#FFFFFF;
          opacity:0.6;
      }
  }

  @media only screen and (min-width :414px) {
      .heroTitle {
          margin-left: 0 !important;
          margin-right: 0 !important;
          font-size: 26px;
          font-weight: 600;
          padding: 0;
          margin-top: 20px;
          padding-bottom: 5px;
          color:#FFFFFF;
      }
      .heroSubTitle {
          margin-left: 10px !important;
          margin-right: 10px !important;
          text-align: center !important;
          font-size: 22px;
          line-height: 30px;
          font-weight: 400;
          padding: 0;
          margin: 0;
          padding-bottom: 30px;
          color:#FFFFFF;
          opacity:0.6;
      }
  }

  @media only screen and (min-width:694px) {

      .icon-svg {
          height: 75px;
          width: 75px;
          margin-top: 40px !important;
      }

      .heroTitle {
          margin-left: 30px !important;
          margin-right: 30px !important;
          font-size: 35px;
          font-weight: 600;
          padding: 0;
          margin-top: 30px;
          padding-bottom: 5px;
          color:#FFFFFF;
      }
      .heroSubTitle {
          margin-left: 90px !important;
          margin-right: 90px !important;
          text-align: center !important;
          font-size: 25px;
          line-height: 36px;
          font-weight: 300;
          padding: 0;
          margin: 0;
          padding-bottom: 40px;
          color:#FFFFFF;
          opacity:0.6;
      }
  }

  @media only screen and (min-width:768px) {
      .heroTitle {
          margin-left: 30px !important;
          margin-right: 30px !important;
          font-size: 35px;
          font-weight: 600;
          padding: 0;
          margin-top: 30px;
          padding-bottom: 5px;
          color:#FFFFFF;
      }
      .heroSubTitle {
          margin-left: 100px !important;
          margin-right: 100px !important;
          text-align: center !important;
          font-size: 25px;
          line-height: 36px;
          font-weight: 300;
          padding: 0;
          margin: 0;
          padding-bottom: 40px;
          color:#FFFFFF;
          opacity:0.6;
      }
  }

  @media only screen and (min-width :992px) {
      .icon-svg {
            height: 80px;
            width: 80px;
            margin-top: 50px !important;
        }

      .heroTitle {
          margin-left: 30px !important;
          margin-right: 30px !important;
          font-size: 40px;
          font-weight: 600;
          padding: 0;
          margin-top: 30px;
          padding-bottom: 5px;
          color:#FFFFFF;
      }
      .heroSubTitle {
          margin-left: 200px !important;
          margin-right: 200px !important;
          text-align: center !important;
          font-size: 29px;
          line-height: 36px;
          font-weight: 300;
          padding: 0;
          margin: 0;
          padding-bottom: 40px;
          color:#FFFFFF;
          opacity:0.6;
      }
  }

  @media only screen and (min-width :1200px) {
      .heroTitle {
          margin-left: 30px !important;
          margin-right: 30px !important;
          font-size: 45px;
          font-weight: 600;
          padding: 0;
          margin-top: 30px;
          padding-bottom: 5px;
          color:#FFFFFF;
      }
      .heroSubTitle {
          margin-left: 260px !important;
          margin-right: 260px !important;
          text-align: center !important;
          font-size: 30px;
          line-height: 36px;
          font-weight: 300;
          padding: 0;
          margin: 0;
          padding-bottom: 40px;
          color:#FFFFFF;
          opacity:0.6;
      }
  }